@import "./components/base";
@import "./components/variables";
@import "./components/common";

html {
  overflow: hidden;
}

.single-form-container {
  min-height: 100vh;
  width: 100vw;
  display: flex;
}

.single-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  max-width: 400px;
  width: 90%;
  margin: 0 auto;
}

.single-form__logo {
  margin: 0 auto;
}

.simple-form__content {

}
